/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui";
import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/Layout";
import Divider from "../components/Divider";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import StyledButtonLink from "../components/StyledButtonLink";
import theme from "../gatsby-plugin-theme-ui/index.js";
import Prism from "@theme-ui/prism";

const components = {
  pre: ({ children }) => <>{children}</>,
  code: Prism,
};

const blogPosts = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx;
  const { previous, next } = pageContext;
  return (
    <Layout
      styling={"layout.secondary"}
      additionalStyles={{ maxWidth: "90rem" }}
    >
      <h1
        sx={{
          textAlign: "center",
        }}
      >
        {frontmatter.title}
      </h1>
      <Divider />
      {frontmatter.tags.includes("blog") ? (
        <p
          sx={{
            textAlign: "center",
          }}
        >
          {frontmatter.date}
        </p>
      ) : null}

      <ThemeProvider theme={theme} components={components}>
        <MDXRenderer
          sx={{
            zIndex: "0",
          }}
          localImages={frontmatter.embeddedImagesLocal}
        >
          {body}
        </MDXRenderer>
      </ThemeProvider>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: "49% 49%",
          gridGap: "0 .2rem",
          py: "2rem",
          px: ["0rem", "2rem", "2rem", "4rem"],
          alignContent: "stretch",
        }}
      >
        <div sx={{ height: "100%", justifySelf: "start", textAlign: "center" }}>
          {previous ? (
            <div
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h5 sx={{ m: ".2rem" }}>Previous Post:</h5>
              <StyledButtonLink
                link={previous.fields.slug}
                styling={"secondary"}
              >
                <BsArrowLeftShort
                  sx={{
                    variant: "icon.primary",
                  }}
                />
                <div sx={{ maxWidth: "85%" }}>{previous.frontmatter.title}</div>
              </StyledButtonLink>
            </div>
          ) : (
            <div sx={{ m: "auto" }} />
          )}
        </div>
        <div sx={{ height: "100%", justifySelf: "end", textAlign: "center" }}>
          {next ? (
            <div
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h5 sx={{ m: ".2rem" }}>Next Post:</h5>
              <StyledButtonLink link={next.fields.slug} styling={"secondary"}>
                <div sx={{ maxWidth: "85%" }}>{next.frontmatter.title}</div>
                <BsArrowRightShort
                  sx={{
                    variant: "icon.primary",
                  }}
                />
              </StyledButtonLink>
            </div>
          ) : (
            <div sx={{ m: "auto" }} />
          )}
        </div>
      </div>
    </Layout>
  );
};
export default blogPosts;
export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "Do MMMM YYYY ")
        description
        tags
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
