/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const StyledButtonLink = (props) => {
  return (
    <Link to={props.link} sx={{ display: "inline-block", height: "100%" }}>
      <button
        sx={{
          variant: `buttons.${props.styling}`,
        }}
      >
        <span
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
            {props.children}
        </span>
      </button>
    </Link>
  );
};

export default StyledButtonLink;
